import React, { Component } from 'react';
import classNames from 'classnames';
import Navigation from '../Navigation/Navigation';
import PlayerHeaderDropdown from '../PlayerHeaderDropdown/PlayerHeaderDropdown';
import PopupPlayer from '../RadioPlayer/PopupPlayer';
import SpectrumAnalyzer from '../SpectrumAnalyzer/SpectrumAnalyzer';
import idx from 'idx';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Playlist from './Playlist';
import { debounce } from 'typescript-debounce-decorator';
import SvgWebcam from './icon_b3_webcam.svg';
import Br3Logo from './br3_logo.svg';
import PropTypes from 'prop-types';
import styles from './PlayerHeader.scss';
import placeholderImage from './cover_fallback.png';

function formatRemaining(r) {
	r = moment.duration(r);
	const hours = r.hours() > 0 ? r.hours() + ':' : '';
	let minutes = r.hours() > 0 && r.minutes() < 10 ? '0' : '';
	minutes += r.minutes() + ':';
	const seconds = r.seconds() > 9 ? r.seconds() : '0' + r.seconds();
	const desc = r.hours() > 0 ? ' h' : ' min';
	return hours + minutes + seconds + desc;
}

export default class PlayerHeader extends Component {
	interval = null;
	playlistReloadInterval = null;

	state = {
		isComponentMounted: false,
		remaining: '',
		title: '',
		interpret: '',
		type: '',
		hasInnerPadding: false,
		thumbnailSource: placeholderImage,
	};

	static propTypes = {
		playlist: PropTypes.shape({
			music: PropTypes.array,
			program: PropTypes.array,
		}).isRequired,
		navigation: PropTypes.object,
	};

	static defaultProps = {
		playlist: {
			music: [],
			program: [],
		},
	};

	componentDidMount = () => {
		this.setState({
			isComponentMounted: true,
		});

		this.interval = setInterval(this.refreshPlaylist, 1000);

		/**
		 * see important note in _onResize
		 */
		this.addResizeHandler();
		this._onResize();
	};

	refreshPlaylist = () => {
		const playlist = new Playlist(this.props.playlist.music);
		const currentSong = playlist.current;

		let thumbnailSource = placeholderImage;

		if (currentSong.image_url && typeof currentSong.image_url !== 'undefined' && currentSong.image_url !== 'undefined') {
			thumbnailSource = currentSong.image_url;
		}

		this.setState({
			type: currentSong.type,
			title: currentSong.title,
			interpret: currentSong.interpret,
			remaining: formatRemaining(currentSong.remaining),
			thumbnailSource,
		});
	};

	componentWillUnmount = () => {
		this.removeResizeHandler();
		this.setState({
			isComponentMounted: false,
		});
		clearInterval(this.interval);
		clearInterval(this.playlistReloadInterval);
	};

	playerUpdateHandler = fd => {
		if (this.state.isComponentMounted) {
			// eslint-disable-next-line react/no-string-refs
			const refs = this.refs;
			if (refs.analyzer) refs.analyzer.paint(fd);
			if (refs.analyzerLine) refs.analyzerLine.paint(fd);
		}
	};

	/**
	 * see important note in _onResize
	 */
	addResizeHandler = () => {
		if (window) {
			window.addEventListener('resize', this._onResize);
		}
	};

	/**
	 * see important note in _onResize
	 */
	removeResizeHandler = () => {
		if (window) {
			window.removeEventListener('resize', this._onResize);
		}
	};

	@debounce(200)
	_onResize() {
		// IMPORTANT NOTE :
		// this fixes an error related to media queries and viewport units
		// it was implemented via CSS and adds a padding of 2em in case we have a ratio of less than 1.62 : 1 (width : height)

		if (window && this.state) {
			const width = window.innerWidth;
			const height = window.innerHeight;

			if (width > 1440) {
				if (width < 1.62 * height) {
					if (!this.state.hasInnerPadding) this.setState({ hasInnerPadding: true });
				} else {
					if (this.state.hasInnerPadding) this.setState({ hasInnerPadding: false });
				}
			} else {
				if (this.state.hasInnerPadding) this.setState({ hasInnerPadding: false });
			}
		}
	}

	render() {
		const playerThumbnailForeground = {
			backgroundImage: 'url(' + this.state.thumbnailSource + ')',
			backgroundSize: '100% auto',
		};

		return (
			<div className={classNames(styles.playerHeader)} style={{ padding: this.state.hasInnerPadding ? '0 2em' : null }}>
				<div className={styles.wrapper}>
					<div className={classNames(styles.logoContainer)}>
						<Link to="/">
							<Br3Logo className={classNames(styles.logo)} />
						</Link>
					</div>
					<div className={classNames(styles.contactContainer)}>
						<div className={classNames(styles.contactText)}>
							<span>Hotline</span> 0800 / 800 3 800
							<br />
							<span>WhatsApp</span> 0174 / 33 43 900
							<br />
							<span>E-Mail</span> <a href="mailto:studio@bayern3.de">studio@bayern3.de</a>
						</div>
					</div>
					<div className={classNames(styles.playerContainer)}>
						<PlayerHeaderDropdown playlist={this.props.playlist.music} className={classNames(styles.playlist)}>
							<div className={classNames(styles.player)}>
								<div className={classNames(styles.playerThumbnail)}>
									<div style={playerThumbnailForeground} />
								</div>
								<div className={classNames(styles.playerSongMeta)}>
									<span className={classNames(styles.playerSongMetaArtist)}>{this.state.type === 'music' ? this.state.interpret : ''}</span>
									<span className={classNames(styles.playerSongMetaTitle)}>
										{this.state.type === 'music' ? this.state.title : idx(this.props, _ => _.playlist.program[0].title) || ''}
									</span>
									<span className={classNames(styles.playerSongDuration)}>
										{this.state.type === 'music'
											? this.state.remaining
											: `bis ${moment(idx(this.props, _ => _.playlist.program[0].timestop)).format('HH:mm')} Uhr`}
									</span>
								</div>
							</div>
						</PlayerHeaderDropdown>
						<div className={classNames(styles.equalizer)}>
							{
								// eslint-disable-next-line react/no-string-refs
								<SpectrumAnalyzer ref="analyzer" />
							}
						</div>
					</div>
					<div className={classNames(styles.buttonContainer)}>
						<PopupPlayer />
						<Link to="/webcam" className={styles.button}>
							<SvgWebcam />
						</Link>
						<Navigation data={this.props.navigation} />
					</div>
				</div>
			</div>
		);
	}
}
