import React from 'react';
import styles from './SocialBar.scss';
import SvgFacebook from './facebook.svg';
import SvgInstagram from './instagram.svg';
import SvgWhatsapp from './whatsapp.svg';
import SvgMail from './mail.svg';
import TikTok from './tiktok.svg';

/**
 * Sidebar with links to social media channels.
 */
export default class SocialBar extends React.Component {
	render() {
		return (
			<div className={styles.socialContainer}>
				<div className={styles.social}>
					<a target="_blank" rel="noopener noreferrer" className={styles.facebook} href="https://www.facebook.com/bayern3/">
						<SvgFacebook />
					</a>
					<a target="_blank" rel="noopener noreferrer" className={styles.instagram} href="https://www.instagram.com/bayern3/">
						<SvgInstagram />
					</a>
					<a target="_blank" rel="noopener noreferrer" className={styles.whatsapp} href="https://wa.me/491743343900">
						<SvgWhatsapp />
					</a>
					<a target="_blank" rel="noopener noreferrer" className={styles.mail} href="mailto:studio@bayern3.de">
						<SvgMail />
					</a>
					<a target="_blank" rel="noopener noreferrer" className={styles.tiktok} href="https://www.tiktok.com/@bayern3">
						<TikTok />
					</a>
				</div>
			</div>
		);
	}
}
